/* START INIT */
*, *::before, *::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
}
.leaflet-container {
  width: 100vw;
  height: 95%;
}
a, button, input { border: none; }
a:focus, button:focus, input:focus { outline: none; }
/* END INIT */

header {
  display: flex;
  z-index: 1000;
  height: 5%;

  background-color: #fff;
  box-shadow: 
  0px 5px 5px -3px rgba(1, 56, 102, 1),
  0px 5px 10px -5px rgba(1, 56, 102, .5),
  0px 0px 100px -5px rgb(1, 56, 102);
}
header button {
  cursor: pointer;
  border: none;
  font-size: 14px;
  color: rgb(0, 78, 129, .5);
  margin-left: 2em;
}
header button:focus {
  outline: none;
}
header button.on {
  color: #0062a3;
  animation: light-on 1s forwards;
}
header button.processing {
  animation: blink .5s infinite;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
@keyframes blink {
  from {
    color: #004e81;
  }
  to {
    color: rgb(0, 78, 129, .5);
  }
}
@keyframes light-on {
  from {
    text-shadow: 
  0px 0px 15px rgb(2, 238, 255);
  }
  to {
    text-shadow: 
    0px 0px 3px rgb(2, 183, 255);
  }
}
.search-address {
  background-color: #fff;
  /* Higher than Map 998 */
  z-index: 1000;
  /* Align input and suggestions */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* On top of layouts */
  position: absolute;
  top: 7%;
  right: 5%;
  /*  */
  width: 25%;
  height: 3em;
}
@media screen and (max-width: 1000px) {
  .search-address {
    width: 60%;
    right: 20%;
  }
}
.input-address {
  /* Align elements input and icon */
  display: flex;
  /* Get label out of alignment */
  position: relative;
}
.input-address--txt {
  padding: 1em 1em .5em 2em;
  width: 65%;
  transition: width .5s;
  font-size: 14px;
}
.input-address--label {
  font-size: 11.5px;
  /* Get label out of alignment */
  position: absolute;
  top: 0em;
  left: 2em;
  /* Make label appears smoothly on input-address--txt:placeholder-shown */
  transition: opacity .5s, top .5s;
}
.fa-map-marker-alt {
  /* border: 1px dashed maroon; */
  background-color: #fff;
  align-self: center;
  font-size: 1.5em;
  /* Position ::after the underline */
  position: relative;
  color: rgba(0, 78, 129, 1);
}
.fa-map-marker-alt::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 50%;
  height: 1px;
  width: 50px;
  background-image: linear-gradient(to right, transparent, rgba(0, 78, 129, .5));

  transition: width .5s;
}
.input-address:focus-within > .fa-map-marker-alt::after {
  width: 150px;
}
.input-address--txt:focus {
  width: calc(100% - 3em);
}
.input-address--txt:placeholder-shown + .input-address--label {
  /* Make label invisible and coming from down */
  opacity: 0;
  visibility: hidden;
  top: 1em;
}
.card-suggestions {
  position: absolute;
  /* Stick to the input bottom - .search-address's height */
  top: 3em;
  width: 100%;
  background-color: #fff;
}
.suggestion-item {
  list-style: none;
  padding: 1em;
}
.suggestion-item:hover {
  cursor: alias;
  outline: 1px solid rgba(0, 78, 129, .5);
}